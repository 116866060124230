<template>
  <section>
    <title-section
      title="Detalle de Línea"
      returnText="Regresar"
      :returnToFunction="returnPrevView"
    >
      <template>
        <template v-if="modeEdit">
          <div class="level-item">
            <button class="button button_micro_dark_outlined" @click="actionModeEdit" :disabled="loading">
              CANCELAR
            </button>
          </div>
          <div class="level-item">
            <button
              class="button button_micro_dark"
              :disabled="loading"
              :class="{ 'is-loading' : loading }"
              @click="updateLineAction"
            >
              GUARDAR
            </button>
          </div>
        </template>
        <template v-else>
          <button
            class="button button_micro_dark"
            @click="actionModeEdit"
          >
            EDITAR
          </button>
        </template>
      </template>
    </title-section>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <template v-if="noInformationObtained">
        <section class="hero">
          <div class="hero-body">
            <div class="container">
              <BaseNotData />
            </div>
          </div>
        </section>
      </template>
      <template v-else>
        <line-main-detail
          ref="lineMainDetailRef"
          :line="lineData"
          :modeEdit="modeEdit"
          @set-image="setImage"
        />
      </template>
    </template>
  </section>
</template>

<script>
import { mapActions } from 'vuex'
import Swal from 'sweetalert2'

export default {
  name: 'LineDetail',
  components: {
    TitleSection: () => import('@/components/UI/TitleSection.vue'),
    LineMainDetail: () => import('@/components/Products/Lines/LineMainDetail.vue'),
    Loading: () => import('@/components/UI/Loading.vue'),
    BaseNotData: () => import('@/components/UI/BaseNotData.vue')
  },
  data () {
    return {
      modeEdit: false,
      lineId: null,
      ASSETS_URL: process.env.VUE_APP_URL_FILES,
      lineData: {
        parentLineName: null,
        active: true,
        coverImage: null,
        subLines: [],
        productsCount: 0
      },
      imageFile: null,
      loading: false,
      noInformationObtained: false,
      payloadBackUp: null
    }
  },
  methods: {
    ...mapActions(['returnPrevView', 'getProductLineDetail', 'updateProductLine']),
    async getLineDetail () {
      const { success, payload } = await this.getProductLineDetail(this.lineId)
      if (success) {
        this.payloadBackUp = payload
        await this.spreadData(payload)
      } else {
        this.noInformationObtained = true
      }
      this.loading = false
      return success
    },
    spreadData (data) {
      this.lineData = {
        coverImage: `${this.ASSETS_URL}${data.lineData.main_image}`,
        active: data.lineData.active,
        parentLineName: data.lineData.productLineName,
        subLines: data.subLinesData,
        productsCount: data.productsCount
      }
      return true
    },
    setImage (event) {
      this.imageFile = event
    },
    async actionModeEdit () {
      this.loading = true
      await this.getLineDetail()
      this.modeEdit = !this.modeEdit
    },
    async updateLineAction () {
      var resp = false
      if (await this.$validator.validateAll()) {
        this.loading = true
        // Se obtienen los datos del componente hijo
        const lineMainData = await this.$refs.lineMainDetailRef.getLocalData()
        // Se crea la nueva estructura de datos para el envio hacia el API
        const dataCreate = {
          lineId: this.lineId,
          parentLineName: lineMainData.parentLineName,
          subLinesList: lineMainData.subLinesList,
          image: this.imageFile,
          active: lineMainData.active
        }
        if (this.lineData.productsCount > 0 && !lineMainData.active) {
          Swal.fire({
            title: `Dejaste una linea con ${this.lineData.productsCount} productos asociados desactivada.`,
            text: 'Al continuar, se deshabilitarán los productos asociados. ¿Deseas continuar?',
            showCancelButton: true,
            confirmButtonText: 'Sí, continuar',
            cancelButtonText: 'Cancelar',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'button button_micro_dark',
              cancelButton: 'button button_micro_dark_outlined'
            },
            reverseButtons: true
          }).then(async result => {
            if (result.isConfirmed) {
              resp = await this.updateProductLine(dataCreate)
            }
          })
        } else {
          resp = await this.updateProductLine(dataCreate)
        }

        if (resp) {
          this.actionModeEdit()
        } else {
          this.loading = false
        }
      }
    }
  },
  async beforeMount () {
    this.loading = true
    this.lineId = await this.$route.params.lineId
    // Obtener las lines y sublineas disponibles
    await this.getLineDetail()
  }
}
</script>

<style lang="scss" scoped>
  .button {
    font-size: 14px;
    width: 150px;
  }
</style>
